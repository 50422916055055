import axios from "axios";

class driverStudentsApi {
    async get({ page, limit, search }) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .get(`/driver_student?page=${page}&limit=${limit}&search=${search}`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async getStudentsNotLinked() {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .get(`/driver_student/student`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async add({ driver_id, driver_students, time, note }) {

        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .post("/driver_student", {
                driver_id,
                driver_students,
                time,
                note,
            })
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async edit({ id, driver_id, driver_students, time, note }) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .put("/driver_student", {
                id,
                driver_id,
                driver_students,
                time,
                note,
            })
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }

    async remove(id) {
        axios.defaults.headers.common.Authorization =
            localStorage.getItem("accessToken");
        return axios
            .delete(`/driver_student/id/${id}`)
            .then((Response) => Response)
            .catch((error) => {
                console.log("error", error);

                return error.response;
            });
    }
}

export default new driverStudentsApi();