<template>
    <v-container fluid class="down-top-padding">
        <v-card class="white pa-3">
            <h1 class="text-center subtitle-4 black--text">الطلاب</h1>
            <h5 class="text-center subtitle-4 black--text mt-1">
                العدد الكلي {{ table.totalData }}
            </h5>
            <v-row class="mt-5">
                <v-col md="4" cols="12">
                    <div class="d-md-flex">
                        <v-btn tile color="info" class="ml-2 mb-4 mb-md-0 mb-sm-0" @click="dialogAdd.open = true">
                            اضافة <v-icon right> fa-plus </v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col md="4" cols="12">
                    <div class="d-flex flex-row">
                        <v-text-field v-model="searching" label="البحث" outlined dense
                            @keyup.enter="search()"></v-text-field>
                        <button class="search-btn" @click="search()">
                            <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
                        </button>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-data-table :headers="table.headers" loading-text="جاري التحميل ... الرجاء الانتظار"
                        :items="table.data" :options.sync="tableOptions" :server-items-length="table.totalData"
                        :loading="table.loading" class="elevation-1" :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-arrow-collapse-left',
                            lastIcon: 'mdi-arrow-collapse-right',
                            prevIcon: 'mdi-plus',
                            nextIcon: 'mdi-minus',
                            itemsPerPageOptions: [10, 50, 100]
                        }">
                        <template slot="item._id" slot-scope="props">
                            {{
                                (tableOptions.page - 1) * tableOptions.itemsPerPage +
                                props.index +
                                1
                            }}
                        </template>
                        <template v-slot:item.students="{ item }">
                            <ol>
                                <li v-for="(student, index) in item.students" :key="index"> {{ student.name }}</li>
                            </ol>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="info" class="mx-2" v-bind="attrs" size="20" v-on="on"
                                        @click="Edit(item)">
                                        fa-edit
                                    </v-icon>
                                </template>
                                <span>تعديل</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="$store.state.results.type === 'admin'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                                        fa-trash
                                    </v-icon>
                                </template>
                                <span>حذف</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>

        <!-- add dialog -->
        <v-dialog v-model="dialogAdd.open" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="headline">اضافة</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form v-model="dialogAdd.isFormValid">
                            <v-row>
                                <!-- time -->
                                <v-col cols="12">
                                    <v-dialog ref="dialog" v-model="time_picker" :return-value.sync="addData.time"
                                        persistent width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="addData.time" label="وقت الدورة"
                                                prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                                                outlined dense clearable></v-text-field>
                                        </template>
                                        <v-time-picker v-if="time_picker" v-model="addData.time" full-width>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="time_picker = false">
                                                الغاء
                                            </v-btn>
                                            <v-btn text color="info" @click="$refs.dialog.save(addData.time)">
                                                حفظ
                                            </v-btn>
                                        </v-time-picker>
                                    </v-dialog>
                                </v-col>
                                <!-- driver_id -->
                                <v-col cols="12">
                                    <v-autocomplete v-model="addData.driver_id" :items="all_drivers.data" item-text="name"
                                        item-value="_id" :rules="Rules.required" dense label="السايق" outlined clearable />
                                </v-col>
                                <!-- driver_students -->
                                <v-col cols="12">
                                    <v-autocomplete v-model="addData.driver_students" :items="all_students_not_linked.data"
                                        item-text="name" item-value="_id" :rules="Rules.required" dense label="الطلاب"
                                        outlined clearable multiple chips deletable-chip />
                                </v-col>
                                <!-- note -->
                                <v-col cols="12">
                                    <v-textarea v-model="addData.note" rows="2" dense label="الملاحظة" outlined />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions class="ml-6">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogAdd.open = false">
                        الغاء
                    </v-btn>
                    <v-btn color="secondary white--text" :loading="dialogAdd.loading" :disabled="!dialogAdd.isFormValid"
                        @click="submitAdd">
                        اضافة
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- add discount dialog -->
        <!-- edit dialog -->
        <v-dialog v-model="dialogEdit.open" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="headline">تعديل</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-form v-model="dialogEdit.isFormValid">
                            <v-row>
                                <!-- time -->
                                <v-col cols="12">
                                    <v-dialog ref="dialogAdd" v-model="time_picker_edit" :return-value.sync="editedItem.time"
                                        persistent width="290px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="editedItem.time" label="وقت الدورة"
                                                prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                                                outlined dense clearable></v-text-field>
                                        </template>
                                        <v-time-picker v-if="time_picker_edit" v-model="editedItem.time" full-width>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="time_picker_edit = false">
                                                الغاء
                                            </v-btn>
                                            <v-btn text color="info" @click="$refs.dialogAdd.save(editedItem.time)">
                                                حفظ
                                            </v-btn>
                                        </v-time-picker>
                                    </v-dialog>
                                </v-col>
                                <!-- driver_id -->
                                <v-col cols="12">
                                    <v-autocomplete v-model="editedItem.driver_id" :items="all_drivers.data"
                                        item-text="name" item-value="_id" :rules="Rules.required" dense label="السايق"
                                        outlined clearable />
                                </v-col>
                                <!-- driver_students -->
                                <v-col cols="12">
                                    <v-autocomplete v-model="editedItem.driver_students" :items="all_students.data"
                                        item-text="name" item-value="_id" :rules="Rules.required" dense label="الطلاب"
                                        outlined clearable multiple chips deletable-chips />
                                </v-col>
                                <!-- note -->
                                <v-col cols="12">
                                    <v-textarea v-model="editedItem.note" rows="2" dense label="الملاحظة" outlined />
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-card-text>
                <v-card-actions class="ml-6">
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogEdit.open = false">
                        الغاء
                    </v-btn>
                    <v-btn color="secondary white--text" :loading="dialogEdit.loading" :disabled="!dialogEdit.isFormValid"
                        @click="submitEdit">
                        تعديل
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- edit discount dialog -->
        <!-- delete dialog -->
        <v-dialog v-model="dialogDelete.open" max-width="500px">
            <v-card style="border-radius: 0">
                <v-card-title class="headline justify-center">
                    هل انت متأكد من حذف هذا الحساب ؟
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialogDelete.open = false">
                        الغاء
                    </v-btn>
                    <v-btn color="primary white--text" :loading="dialogDelete.loading" @click="deleteItemConfirm">
                        حذف
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End delete dialog -->
        <!--- respondMessageVue -->
        <respondMessageVue :dialogData="dialogData"></respondMessageVue>
    </v-container>
</template>

<script>
// ES modules
import driverStudentsApi from '@/api/driverStudents'
import driversApi from '@/api/drivers'
import customersApi from '@/api/customers'
import respondMessageVue from '@/components/respondMessage.vue'
import { encrypt, decrypt } from '@/constants/cryptUrl'
import randPass from '@/constants/rand_pass'

export default {
    name: 'DriverStudents',

    components: {
        respondMessageVue,
    },

    data: () => ({
        content_url: null,

        time_picker: false,

        time_picker_edit: false,

        birthday_menu: false,

        birthday_menu_edit: false,

        rate: 0,

        errors: {},

        is_active_select_items: [
            { text: 'مفعل', value: true },
            { text: 'غير مفعل', value: false }
        ],

        gender_select: ['ذكر', 'انثى'],

        addData: {
            driver_id: null,
            driver_students: null,
            time: null,
            note: null,
        },

        Rules: {
            required: [value => !!value || 'الحقل مطلوب']
        },

        searching: null,

        tableOptions: {
            page: 1,
            itemsPerPage: 10,
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: false,
            sortBy: [],
            sortDesc: [],
            search: null,
            firstTime: true
        },

        table: {
            search: null,
            totalData: 0,
            data: [],
            loading: true,

            headers: [
                {
                    text: '#',
                    align: 'start',
                    sortable: false,
                    value: '_id'
                },
                {
                    text: 'الوقت',
                    sortable: false,
                    value: 'time'
                },
                {
                    text: 'السايق',
                    sortable: false,
                    value: 'driver_name'
                },
                {
                    text: 'الطلاب',
                    sortable: false,
                    value: 'students'
                },
                {
                    text: 'الملاحظة',
                    sortable: false,
                    value: 'note'
                },
                { text: 'العمليات', value: 'actions', sortable: false }
            ]
        },

        dialogData: {
            open: false,
            color: 'info',
            bodyText: 'test'
        },

        editedItem: {
            _id: null,
            driver_id: null,
            driver_students: [],
            time: null,
            note: null,
        },

        dialogDelete: {
            open: false,
            loading: false
        },

        dialogEdit: {
            open: false,
            loading: false,
            isFormValid: false
        },

        dialogAdd: {
            open: false,
            loading: false,
            isFormValid: false
        },

        all_drivers: {
            loading: false,
            data: []
        },

        all_students_not_linked: {
            loading: false,
            data: []
        },

        all_students: {
            loading: false,
            data: []
        },
    }),

    watch: {
        tableOptions: {
            // immediate: false,

            handler() {
                if (!this.tableOptions.firstTime) {
                    this.$router.push(
                        {
                            query: {
                                filter: encrypt({
                                    page: this.tableOptions.page,
                                    limit: this.tableOptions.itemsPerPage,
                                    search: this.table.search
                                })
                            }
                        },
                        () => { }
                    )
                }

                this.tableOptions.firstTime = false

                // this.getDataAxios();
            }
            // deep: true,
        },

        '$route.query.filter': {
            handler() {
                if (!this.$route.query.filter) {
                    this.tableOptions.page = 1
                    this.tableOptions.itemsPerPage = 10
                    this.table.search = null
                    this.searching = null
                    return
                }
                const filterData = decrypt(this.$route.query.filter)

                let page = filterData.page
                let limit = filterData.limit
                let search = filterData.search

                this.tableOptions.page = +page
                this.tableOptions.itemsPerPage = +limit
                this.table.search = search
                this.searching = search

                this.getDataAxios()
            }
        },

        'table.search': {
            handler() {
                this.tableOptions.page = 1
                this.tableOptions.itemsPerPage = 10
                this.$router.push(
                    {
                        query: {
                            filter: encrypt({
                                page: this.tableOptions.page,
                                limit: this.tableOptions.itemsPerPage,
                                search: this.table.search
                            })
                        }
                    },
                    () => { }
                )
            }
        }
    },

    created() {
        this.getDataAxios()
        this.getAllDrivers()
        this.getAllStudents()
        this.getAllNotLinkedStudents()
    },
    methods: {
        async getDataAxios() {
            this.table.loading = true
            let search = this.table.search
            let page = this.tableOptions.page
            let limit = this.tableOptions.itemsPerPage

            if (!search) {
                search = ''
            }

            const response = await driverStudentsApi.get({
                page,
                limit,
                search,
            })

            if (response.status === 401) {
                this.$store.dispatch('submitLogout')
            } else if (response.status === 500) {
                this.table.loading = false
                this.showDialogFunction(response.data.results, '#FF5252')
            } else {
                this.table.loading = false
                this.table.data = response.data.results.data
                this.table.totalData = response.data.results.count
            }
        },

        async getAllDrivers() {
            this.all_drivers.loading = true

            const response = await driversApi.getAll()

            if (response.status === 401) {
                this.$store.dispatch('submitLogout')
            } else if (response.status === 500) {
                this.all_drivers.loading = false
                this.showDialogFunction(response.data.results, '#FF5252')
            } else {
                this.all_drivers.loading = false
                this.all_drivers.data = response.data.results
            }
        },

        async getAllNotLinkedStudents() {
            this.all_students_not_linked.loading = true

            const response = await driverStudentsApi.getStudentsNotLinked()

            if (response.status === 401) {
                this.$store.dispatch('submitLogout')
            } else if (response.status === 500) {
                this.all_students_not_linked.loading = false
                this.showDialogFunction(response.data.results, '#FF5252')
            } else {
                this.all_students_not_linked.loading = false
                this.all_students_not_linked.data = response.data.results
            }
        },

        async getAllStudents() {
            this.all_students.loading = true

            const response = await customersApi.getAll()

            if (response.status === 401) {
                this.$store.dispatch('submitLogout')
            } else if (response.status === 500) {
                this.all_students.loading = false
                this.showDialogFunction(response.data.results, '#FF5252')
            } else {
                this.all_students.loading = false
                this.all_students.data = response.data.results
            }
        },

        Edit(item) {
            this.editedItem = { ...item }
            this.dialogEdit.open = true
        },

        async submitEdit() {
            this.dialogEdit.loading = true

            const response = await driverStudentsApi.edit({
                id: this.editedItem._id,
                driver_id: this.editedItem.driver_id,
                driver_students: this.editedItem.driver_students,
                time: this.editedItem.time,
                note: this.editedItem.note,
            })

            if (response.status === 401) {
                this.$store.dispatch('submitLogout')
            } else if (response.status === 500) {
                this.dialogEdit.open = false
                this.dialogEdit.loading = false
                this.showDialogFunction(response.data.message, '#FF5252')
            } else {
                this.dialogEdit.loading = false
                this.dialogEdit.open = false
                this.getDataAxios()
                this.showDialogFunction(response.data.message, 'info')
            }
        },

        deleteItem(item) {
            this.deletedItem = { ...item }
            this.dialogDelete.open = true
        },

        async deleteItemConfirm() {
            this.dialogDelete.loading = true

            const response = await driverStudentsApi.remove(this.deletedItem._id)

            if (response.status === 401) {
                this.$store.dispatch('submitLogout')
            } else if (response.status === 500) {
                this.dialogDelete.loading = false
                this.dialogDelete.open = false
                this.showDialogFunction(response.data.message, '#FF5252')
            } else {
                this.dialogDelete.loading = false
                this.dialogDelete.open = false
                this.getDataAxios()
                this.showDialogFunction(response.data.message, 'info')
            }
        },

        async submitAdd() {
            this.dialogAdd.loading = true

            const response = await driverStudentsApi.add({
                driver_id: this.addData.driver_id,
                driver_students: this.addData.driver_students,
                time: this.addData.time,
                note: this.addData.note,
            })

            if (response.status === 401) {
                this.$store.dispatch('submitLogout')
            } else if (response.status === 500) {
                this.dialogAdd.open = false
                this.dialogAdd.loading = false
                this.showDialogFunction(response.data.message, '#FF5252')
            } else {
                this.dialogAdd.loading = false
                this.dialogAdd.open = false

                this.addData = {
                    driver_id: null,
                    driver_students: null,
                    time: null,
                    note: null,
                }

                this.showDialogFunction(response.data.message, 'info')
                this.getDataAxios()
            }
        },

        search() {
            this.table.search = this.searching
        },

        randPass,

        showDialogFunction(bodyText, color) {
            this.dialogData.open = true
            this.dialogData.bodyText = bodyText
            this.dialogData.color = color
        },

        removeSelectedOption(index) {
            this.editedItem.driver_students.splice(index, 1)
        },
    }
}
</script>